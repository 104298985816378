var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "navbar-container" }, [_c("Navbar")], 1),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "page-controller" }, [
          _c(
            "div",
            { staticClass: "go-back-div", on: { click: _vm.handleGoBack } },
            [
              _vm._m(0),
              _c("div", { staticClass: "go-back-text" }, [
                _vm._v("Back to Dashboard"),
              ]),
            ]
          ),
        ]),
        _vm._m(1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("img", {
        staticStyle: { "margin-right": "9px" },
        attrs: { src: "/img/onboarding/back-icon.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("iframe", {
        attrs: {
          src: "https://my.forms.app/form/620a9201015f7839d84af8c2",
          width: "100%",
          height: "630px",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }