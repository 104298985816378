<template>
  <div>
    <div class="navbar-container">
      <Navbar />
    </div>
    <div class="container-fluid">
      <div class="content-container">
        <div class="page-controller">
          <div class="go-back-div" @click="handleGoBack">
            <div class="">
              <img
                src="/img/onboarding/back-icon.svg"
                style="margin-right: 9px"
              />
            </div>
            <div class="go-back-text">Back to Dashboard</div>
          </div>
        </div>
        <div>
          <iframe
            src="https://my.forms.app/form/620a9201015f7839d84af8c2"
            width="100%"
            height="630px"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/onboarding_navbar";

export default {
  props: {},
  components: {
    Navbar,
  },

  data: () => ({}),

  created() {},
  methods: {
    handleGoBack() {
      this.$router.push({ name: "user_type" });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.navbar-container {
  position: fixed;
  width: 100%;
  z-index: 100;
}
.container-fluid {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f7f7f8;
  padding-bottom: 100px;
  /* height: calc(100vh - 75px); */
}
.content-container {
  width: 846px;
  /* height: calc(100vh - 75px); */
}
.page-controller {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.go-back-div {
  display: flex;
  cursor: pointer;
}
.go-back-text {
  color: #0055a5;
  font-size: 14px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
  margin-left: 9px;
}
</style>
